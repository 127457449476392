<template>
  <base-section id="about">
    <base-section-heading
      color="grey lighten-2"
      icon="$logo"
      title="О компании"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="6"          
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAbout',

    data: () => ({
      cards: [
        {
          title: 'Команда профессионалов',
          subtitle: 'Кто мы?',
          text: 'ООО «ИЛ Кооператив» – динамично развивающаяся многопрофильная компания, успешно функционирующая в сфере разрешительной документации за счет сформировавшейся команды компетентных специалистов, имеющих за плечами значительный опыт работы.',
          callout: '|',
        },
        {
          title: 'Делаем рутинную работу простой и удобной',
          subtitle: 'Что мы делаем?',
          text: 'Компания работает в сфере подтверждения соответствия продукции, услуг, систем менеджмента и оказывает содействие в подборе аккредитованных лиц для проведения обязательной и добровольной оценки согласно действующей нормативной документации на территории Российской Федерации, Евразийского экономического союза (ЕАЭС), международным требованиям.',
          callout: '|',
        },        
      ],
    }),
  }
</script>
